import { Box, Container, Typography, Divider } from "@material-ui/core"

export default function SiteNotice() {
    return (
        <Container>
            {/* <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider/></Box> */}
            <Typography variant="h2">Imprint</Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            <Typography variant="h4">Fraunhofer Institute for Computer Graphics Research IGD</Typography>
            <Box sx={{m: 2}}>
                <Typography>Fraunhoferstraße 5</Typography>
                <Typography>64283 Darmstadt</Typography>
                <Typography>Telefon: +49 6151 155-0</Typography>
                <Typography>Fax: +49 6151 155-199</Typography>
                <Typography>E-Mail: info@igd.fraunhofer.de</Typography>
                <Typography>www.igd.fraunhofer.de</Typography>
            </Box>
            <Typography>The institute is a constituent entity of the Fraunhofer-Gesellschaft, and as such has no separate legal status.</Typography>
            <Box sx={{m: 2}}>
                <Typography variant="h6">Fraunhofer-Gesellschaft zur Förderung der angewandten Forschung e.V.</Typography>
                <Typography>Hansastraße 27 c</Typography>
                <Typography>80686 München</Typography>
                <Typography>Telefon: +49 89 1205-0</Typography>
                <Typography>Fax: +49 89 1205-7531</Typography>
                <Typography>www.fraunhofer.de</Typography>
            <Typography><b>VAT Identification Number</b> in accordance with §27 a VAT Tax Act: DE 129515865</Typography>
            <Typography variant="h6">Court of jurisdiction</Typography>
            <Typography>Amtsgericht München (district court)</Typography>
            <Typography>Registered nonprofit association</Typography>
            <Typography>Registration no. VR 4461</Typography>
            </Box>

            <Typography>Responsible Editor: <b><a href="mailto:philipp.urban@igd.fraunhofer.de">Philipp Urban</a></b></Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            <Box sx={{m: 2, marginLeft: 0}}>
                <Typography variant="h4">Executive Board</Typography>
                <Typography> <b>Prof. Holger Hanselka </b> |  President</Typography>
                <Typography> <b>Prof. Constantin Häfner </b> | Member of the executive board</Typography>
                <Typography> <b>Prof. Axel Müller-Groeling </b> | Member of the executive board</Typography>
                <Typography> <b>Elisabeth Ewen </b> | Member of the executive board</Typography>
                <Typography> <b>Dr. Sandra Krey </b> | Member of the executive board</Typography>
            </Box>
            
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            <Box sx={{m:2, marginLeft: 0}}>
                <Typography variant="h4">Usage Rights</Typography>
                <Typography>Copyright © by</Typography>
                <Typography>Fraunhofer-Gesellschaft</Typography>
                <Typography>All rights reserved.</Typography>
                <Typography>All copyright for this Web site are owned in full by the Fraunhofer-Gesellschaft.</Typography>
                <Typography>Permission is granted to download or print material published on this site for personal use only. Its use for any other purpose, and in particular its commercial use or distribution, are strictly forbidden in the absence of prior written approval. Please address your requests for approval to:</Typography>
                
                <Box sx={{m:2, marginLeft: 0}}>
                    <Typography>Fraunhofer Institute for Graphische Datenverarbeitung IGD</Typography>
                    <Typography>Fraunhoferstraße 5</Typography>
                    <Typography>64283 Darmstadt</Typography>
                    <Typography>Telefon: +49 6151 155-0</Typography>
                    <Typography>Fax: +49 6151 155-199</Typography>
                    <Typography>E-Mail: info@igd.fraunhofer.de</Typography>
                    <Typography>www.igd.fraunhofer.de</Typography>
                </Box>

                <Box sx={{m: 2, marginLeft: 0}}><Typography>Notwithstanding this requirement, material may be downloaded or printed for use in connection with press reports on the activities of the Fraunhofer-Gesellschaft and its constituent institutes, on condition that the following terms are complied with:</Typography></Box>
                <Typography>No alterations may be made to pictorial content, with the exception of framing modifications to emphasize the central motif. The source must be quoted and two free reference copies must be sent to the above-mentioned address. Such usage is free of charge.</Typography>    
            </Box>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            <Typography variant="h4">Disclaimer</Typography>
            <Box sx={{m: 2, marginLeft: 0}}><Typography>We cannot assume any liability for the content of external pages. Solely the operators of those linked pages are responsible for their content.</Typography></Box>
            <Box sx={{m: 2, marginLeft: 0}}><Typography>We make every reasonable effort to ensure that the content of this Web site is kept up to date, and that it is accurate and complete. Nevertheless, the possibility of errors cannot be entirely ruled out. We do not give any warranty in respect of the timeliness, accuracy or completeness of material published on this Web site, and disclaim all liability for (material or non-material) loss or damage incurred by third parties arising from the use of content obtained from the Web site.</Typography></Box>
            <Box sx={{m: 2, marginLeft: 0}}><Typography>Registered trademarks and proprietary names, and copyrighted text and images, are not generally indicated as such on our Web pages. But the absence of such indications in no way implies that these names, images or text belong to the public domain in the context of trademark or copyright law.</Typography></Box>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
        </Container>
    )
}