import release2020R1 from "./2020R1.md";
import release2020R2 from "./2020R2.md";
import release2021R1 from "./2021R1.md";
import release2021R2 from "./2021R2.md";
import release2022R1 from "./2022R1.md";
import release2022R2 from "./2022R2.md";
import release2023R1 from "./2023R1.md";
import release2023R2 from "./2023R2.md";
import release2024_R2_1 from "./2024.R2.1.md";
import release2024_R2_1a from "./2024.R2.1a.md";
import release2024R1 from "./2024R1.md";
import release2024R2 from "./2024R2.md";

const releaseNotes = [
    {
        title: "Cuttlefish© 2024.R2.1a",
        date: "2025-03-27",
        description: release2024_R2_1a
    },
    {
        title: "Cuttlefish® 2024.R2.1",
        date: "2025-03-10",
        description: release2024_R2_1
    },
    {
        title: "Cuttlefish® 2024.R2",
        date: "2024-11-15",
        description: release2024R2
    },
    {
        title: "Cuttlefish® 2024.R1",
        date: "2024-05-08",
        description: release2024R1
    },
    {
        title: "Cuttlefish® 2023.R2",
        date: "2023-10-31",
        description: release2023R2
    },
    {
        title: "Cuttlefish® 2023.R1",
        date: "2023-05-22",
        description: release2023R1
    },
    {
        title: "Cuttlefish® 2022.R2",
        date: "2022-11-08",
        description: release2022R2
    },
    {
        title: "Cuttlefish® 2022.R1",
        date: "2022-05-02",
        description: release2022R1
    },
    {
        title: "Cuttlefish® 2021.R2",
        date: "2021-11-03",
        description: release2021R2
    },
    {
        title: "Cuttlefish® 2021.R1",
        date: "2021-03-30",
        description: release2021R1
    },
    {
        title: "Cuttlefish® 2020.R2",
        date: "2020-10-27",
        description: release2020R2
    },
    {
        title: "Cuttlefish® 2020.R1",
        date: "2020-05-04",
        description: release2020R1
    }
];

export default releaseNotes;